import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export function InsomniaLogoIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={twMerge('h-[35px] w-[35px]', className)}
      height={35}
      width={35}
      xmlSpace="preserve"
      preserveAspectRatio="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M16 31.1864C24.3873 31.1864 31.1865 24.3872 31.1865 16C31.1865 7.61276 24.3873 0.813559 16 0.813559C7.6128 0.813559 0.813599 7.61276 0.813599 16C0.813599 24.3872 7.6128 31.1864 16 31.1864Z"
        fill="white"
      />
      <path
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16 1.62712C23.9379 1.62712 30.3729 8.06208 30.3729 16C30.3729 23.9379 23.9379 30.3729 16 30.3729C8.06208 30.3729 1.62712 23.9379 1.62712 16C1.62712 8.06208 8.06208 1.62712 16 1.62712Z"
        fill="#4000BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1807 4.61017C22.4712 4.61017 27.5706 9.70957 27.5706 16C27.5706 22.2904 22.4712 27.3898 16.1807 27.3898C9.89029 27.3898 4.79089 22.2904 4.79089 16C4.79089 14.4627 5.09547 12.9965 5.64758 11.6584C6.45373 12.7562 7.75377 13.4689 9.22027 13.4689C11.6666 13.4689 13.6496 11.4858 13.6496 9.03955C13.6496 7.57305 12.937 6.27301 11.8391 5.46687C13.1772 4.91475 14.6434 4.61017 16.1807 4.61017Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.1807"
          y1="27.3898"
          x2="16.1807"
          y2="4.61017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7400E1" />
          <stop offset="1" stopColor="#4000BF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
