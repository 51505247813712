import React from 'react';
import { Link as RemixLink } from '@remix-run/react';

export type AnchorProps = Omit<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'href' | 'ref'
>;

interface Props extends AnchorProps {
  children: React.ReactNode;
  to: string;
  // TODO(johnwchadwick): Investigate proper typing.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event?: any;
  disabled?: boolean;
}

const Link = ({ children, to, ...props }: Props) => {
  const addedProps: Pick<Props, 'onClick'> = {};

  if (!props.onClick && props.event && props.event.event && props.event.properties) {
    addedProps.onClick = () => {
      //@ts-expect-error - analytics is a global variable
      window.analytics &&
        //@ts-expect-error - analytics is a global variable
        window.analytics.track(props.event.event, props.event.properties);
    };
  }

  if (to.match(/^(http|https|insomnia|insomniad):\/\//)) {
    return (
      <a href={to} {...addedProps} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <RemixLink to={to} {...addedProps} {...props}>
        {children}
      </RemixLink>
    );
  }
};

export default Link;
